<!-- Title Bar -->
<div class="title-bar">
    <div class="title">
        <h1 title="{{ 'MY_ACCOUNT' | translate }}">{{ "MY_ACCOUNT" | translate }}</h1>
    </div>
</div>

<div class="tab-container">
    <content-loading-animation *ngIf="loading"></content-loading-animation>
    <!-- Form -->
    <form
        id="form"
        name="form"
        #form="ngForm"
        (ngSubmit)="form.valid && onSubmit(form)"
        *ngIf="!loading"
        class="my-account-container"
        [mustMatch]="['newPassword', 'confirmPassword']"
    >
        <div class="row">
            <div class="col-12 col-md-6">
                <!-- Name -->
                <div class="form-group">
                    <label for="name">{{ "NAME" | translate }}</label>
                    <input class="form-control-plaintext" id="name" name="name" value="{{ user.name }}" disabled="true" />
                </div>
                <!-- Email -->
                <div class="form-group">
                    <label for="email">{{ "EMAIL" | translate }}</label>
                    <input class="form-control-plaintext" id="email" name="email" value="{{ user.email }}" disabled="true" />
                </div>
                <!-- SSO -->
                <div class="form-group" *ngIf="user.sso_id">
                    <label for="ssoId">{{ "SSO" | translate }}</label>
                    <input class="form-control-plaintext" id="ssoId" name="ssoId" value="{{ user.SSO.name }}" disabled="true" />
                </div>
                <!-- Account Management Notifications -->
                <div class="form-group" *ngIf="user.is_admin">
                    <button type="button" class="btn btn-outline-primary" (click)="toggleAccountManagementNotification()">
                        {{ user.has_account_management_notifications ? ("DISABLE" | translate) : ("ENABLE" | translate) }}
                        {{ "ACCOUNT_MANAGEMENT" | translate }}
                        {{ "NOTIFICATIONS" | translate }}
                    </button>
                </div>
                <!-- Change Password -->
                <button type="button" class="btn btn-outline-primary" (click)="enableEditMode()" *ngIf="!isEditModeEnabled && !user.sso_id">
                    <fa-icon icon="pencil" size="sm"></fa-icon>
                    {{ "CHANGE_PASSWORD" | translate }}
                </button>
            </div>
        </div>

        <div class="row" *ngIf="isEditModeEnabled && editingAccount">
            <div class="col-12 col-md-6">
                <!-- Current Password -->
                <div class="form-group">
                    <label for="currentPassword" [ngClass]="{ 'is-invalid': form.submitted && currentPassword.errors }">
                        {{ "CURRENT" | translate }} {{ "PASSWORD" | translate }}
                        <fa-icon icon="asterisk" size="xs"></fa-icon>
                    </label>
                    <input
                        type="password"
                        id="currentPassword"
                        name="currentPassword"
                        autocomplete="cc-csc"
                        placeholder="{{ 'CURRENT' | translate }} {{ 'PASSWORD' | translate }}"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': form.submitted && currentPassword.errors }"
                        [(ngModel)]="editingAccount.currentPassword"
                        required
                        #currentPassword="ngModel"
                    />
                    <div *ngIf="currentPassword.invalid" class="invalid-feedback">
                        <div *ngIf="currentPassword.errors.required">
                            {{ "CURRENT" | translate }} {{ "PASSWORD" | translate }} {{ "IS_REQUIRED" | translate }}.
                        </div>
                    </div>
                </div>
                <!-- New Password -->
                <div class="form-group">
                    <label for="newPassword" [ngClass]="{ 'is-invalid': form.submitted && newPassword.errors }">
                        {{ "NEW" | translate }} {{ "PASSWORD" | translate }}
                        <fa-icon icon="asterisk" size="xs"></fa-icon>
                    </label>
                    <input
                        type="password"
                        autocomplete="cc-csc"
                        id="newPassword"
                        name="newPassword"
                        placeholder="{{ 'NEW' | translate }} {{ 'PASSWORD' | translate }}"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': form.submitted && newPassword.errors }"
                        [(ngModel)]="editingAccount.newPassword"
                        required
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$"
                        #newPassword="ngModel"
                    />
                    <div *ngIf="newPassword.invalid" class="invalid-feedback">
                        <div *ngIf="newPassword.errors.required">{{ "NEW" | translate }} {{ "PASSWORD" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        <div *ngIf="newPassword.errors.pattern">{{ "PASSWORD_MUST_BE" | translate }}.</div>
                    </div>
                </div>
                <!-- Confirm Password -->
                <div class="form-group">
                    <label for="confirmPassword" [ngClass]="{ 'is-invalid': form.submitted && confirmPassword.errors }">
                        {{ "CONFIRM" | translate }} {{ "NEW" | translate }} {{ "PASSWORD" | translate }}
                        <fa-icon icon="asterisk" size="xs"></fa-icon>
                    </label>
                    <input
                        type="password"
                        autocomplete="cc-csc"
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="{{ 'CONFIRM' | translate }} {{ 'NEW' | translate }} {{ 'PASSWORD' | translate }}"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': form.submitted && confirmPassword.errors }"
                        [(ngModel)]="editingAccount.confirmPassword"
                        required
                        #confirmPassword="ngModel"
                    />
                    <div *ngIf="confirmPassword.invalid" class="invalid-feedback">
                        <div *ngIf="confirmPassword.errors.required">
                            {{ "CONFIRM" | translate }} {{ "NEW" | translate }} {{ "PASSWORD" | translate }} {{ "IS_REQUIRED" | translate }}.
                        </div>
                        <div *ngIf="confirmPassword.errors.mustMatch">{{ "PASSWORDS_MUST_MATCH" | translate }}.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="isEditModeEnabled">
            <div class="col-12 col-md-6">
                <app-error [marginBottom]="true" [marginTop]="false"></app-error>
            </div>
        </div>
        <div class="row" *ngIf="isEditModeEnabled">
            <div class="col-12 col-md-6 text-start">
                <button zmid="form-submit" type="submit" class="btn btn-primary" [disabled]="saving">
                    <fa-icon icon="check" size="sm"></fa-icon>
                    {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                </button>
                <button type="button" class="btn btn-outline-secondary ms-2" (click)="disableEditMode()" [disabled]="saving">
                    <fa-icon icon="times"></fa-icon>
                    {{ "CANCEL" | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
