import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../../modules/shared/shared.module";

// Forms
import { FormsModule } from "@angular/forms";

// Components
import { MyAccountComponent } from "./my-account.component";

// Routes
import { MyAccountRouting } from "./my-account.routing";

@NgModule({
    imports: [CommonModule, FormsModule, NgbModule, FontAwesome, SharedModule, MyAccountRouting],
    declarations: [MyAccountComponent]
})
export class MyAccountModule {}
