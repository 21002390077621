import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";

import { UsersService } from "../users/users.service";
import { User } from "../../../models/shared";
import { NgForm } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { firstValueFrom } from "rxjs";

export class EditingAccount {
    newPassword: string;
    confirmPassword: string;
    currentPassword: string;
}

@Component({
    selector: "app-my-account",
    templateUrl: "./my-account.component.html"
})
export class MyAccountComponent implements OnInit {
    user: User;
    loading = true;
    saving = false;
    isEditModeEnabled = false;
    editingAccount: EditingAccount;

    constructor(
        private userService: UsersService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        // Set Title
        this.titleService.setTitle(this.translate.instant("MY_ACCOUNT"));
    }

    ngOnInit() {
        this.editingAccount = new EditingAccount();

        this.userService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.user = user;
                this.loading = false;
            });
    }

    enableEditMode() {
        this.editingAccount.currentPassword = "";
        this.editingAccount.newPassword = "";
        this.editingAccount.confirmPassword = "";
        this.isEditModeEnabled = true;
    }

    disableEditMode() {
        this.isEditModeEnabled = false;
    }

    async onSubmit(form: NgForm) {
        this.saving = true;

        const model = {
            current_password: this.editingAccount.currentPassword,
            password: this.editingAccount.newPassword
        };

        const result = await this.userService.updateUser(this.user.id, model);

        if (result) {
            this.isEditModeEnabled = false;
            form.resetForm();
        }

        this.saving = false;
    }

    async toggleAccountManagementNotification() {
        const response = await firstValueFrom(
            this.userService.setAccountManagementNotifications(!this.user.has_account_management_notifications)
        );
        if (response.success) {
            this.user = response.result;
        }
    }
}
