import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../guards/auth.guard";

import { Constants } from "../../../constants/constants";
import { MyAccountComponent } from "./my-account.component";

const myAccountRoutes: Routes = [
    {
        path: Constants.urls.accountManagement.myAccount,
        component: MyAccountComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(myAccountRoutes)],
    exports: [RouterModule]
})
export class MyAccountRouting {}
